import React from "react";
// Customizable Area Start
import {
  StyleSheet,
  Text,
  Image,
  TouchableOpacity,
  View,
  ScrollView,
  TouchableWithoutFeedback,
  FlatList,
  SafeAreaView,
  ListRenderItemInfo
} from "react-native";
import { AllCategory } from "./FilteroptionsController";
import {Grid, Box,
  Button,
  InputBase,
  Typography,
  IconButton,
  Menu,
  Paper,
  MenuItem,} from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import {Bag,prdImg} from './assets'
import SearchIcon from '@mui/icons-material/Search';
import SortIcon from '@mui/icons-material/Sort';
import FormatListBulletedRoundedIcon from '@mui/icons-material/FormatListBulletedRounded';
import WindowOutlinedIcon from '@mui/icons-material/WindowOutlined';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ProductCard from "./components/ProductCard";
import { AppHeader } from "../../../components/src/AppHeader.web";
// Customizable Area End

import FilteritemsController, {
  Props,
  configJSON
} from "./FilteritemsController";
import Icon from "react-native-vector-icons/FontAwesome";

export default class Filteritems extends FilteritemsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  LinearProductCard =({attributes,id ,isBuyer ,handelDelete,openDiscription}:{attributes:any,id:string|number,isBuyer:boolean , handelDelete: ()=>void , openDiscription:()=>void })=>{
    const { name,  price, classification, min_quantity, available_quantity ,images} = attributes;

    return (  
    <Paper
      elevation={0}
      key={id}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingX:'21px',
        paddingTop:'0px',
        fontFamily: 'Inter',
        borderBottom: '1px solid #E7E5E4',
        width: '100%',    
        maxWidth:'1177px',
        height:'124px',
        alignSelf:'center',
        justifySelf:'center',
        marginLeft:'20px'

      }}
    >
      {/* Product Image */}
      <Box sx={{ display: 'flex', alignItems: 'center',padding:0 , cursor:'pointer'}} 
       datat-test-id='dltbtn'>
        <Box
          component="img"
          src={ (Array.isArray(images) && images.length) ? images[0].url:prdImg.default}
          alt={name}
          sx={{ width: 108, height: 86, borderRadius: 2, marginRight: 2 ,objectFit:'cover', cursor:'pointer'}}
          onClick={openDiscription}
        />

        {/* Product Info */}
        <Box sx={{display:'flex', flexDirection:'column',alignItems:'start', gap:'4px'}}>
          <Typography variant="body1" sx={{ fontWeight: 400 , fontFamily:'Inter' , fontSize:14 }}>
            {name} <Typography component="span" sx={{ color: '#059669', fontWeight: 400 , fontFamily:'Inter' , fontSize:14}}> (seed stock) </Typography>
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: 700, color: '#334155', fontFamily:'Inter',fontSize:18 }}>
            ${price} USD
          </Typography>
         { !isBuyer? <>
          <Typography variant="body2" color="#334155" sx={{fontWeight: 400 ,color:'#334155', fontFamily:'Inter' , fontSize:14}} >
            Minimum order qty: {min_quantity}
          </Typography>
          <Typography variant="body2" sx={{fontWeight: 400 ,color:'#334155', fontFamily:'Inter' , fontSize:14}}>
            Classification: <Typography component="span" color={'#4F46E5'} sx={{ fontWeight: 400 ,color:'#4F46E5', fontFamily:'Inter' , fontSize:14}}>{classification}</Typography>
          </Typography>
          </>:<>
          <Typography variant="h6" sx={{ fontWeight: 700, color: '#334155', fontFamily:'Inter',fontSize:12 ,background:'#F3F3F3', padding:'2px 8px', borderRadius:'8px'}}>
            Breeder :<Typography component="span" color={'#44403C'} sx={{ fontWeight: 400 ,color:'#44403C', fontFamily:'Inter' , fontSize:14}}> &nbsp;Auto Seeds</Typography>
        
          </Typography>
          </>}
        </Box>
      </Box>

      {/* Available Quantity */}
     { !this.state.isBuyer ?<Box sx={{ display: 'flex', alignItems: 'center' , gap:'171.5px'}}>
        <Box 
        sx={{
          backgroundColor: '#f5f5f4',
          borderRadius: '8px',
          textAlign:'center',
          justifyContent:'center',
          alignItems:'center',
          marginRight: 2,
          width:'158px',
          height:'48px',
          gap:'6px'
        }}
        >
          
        <Typography
          variant="body2"
              sx={{
                fontWeight: 700,
                fontFamily: 'Inter',
                marginTop:'13px',
                fontSize:14
              }}
        >
          {available_quantity} Qty
        </Typography>
        </Box>

        {/* Action Buttons */}
        <Box>
        <IconButton sx={{borderRight:'1px solid #E2E8F0', borderRadius:0}}  onClick={handelDelete}
        >
          <DeleteOutlineRoundedIcon sx={{color:"#334155" }}/>
          <Typography variant="body2"sx={{
                fontWeight: 400,
                fontFamily: 'Inter',
                marginLeft:'6px',
                fontSize:14,
                color:'#334155'
              }}>Delete</Typography>
        </IconButton>
        <IconButton>
          <EditIcon  sx={{color:"#44403C" }} />
          <Typography variant="body2" sx={{
                 fontWeight: 400,
                 fontFamily: 'Inter',
                 marginLeft:'6px',
                 fontSize:14,
                 color:'#44403C'
              }}>Edit</Typography>
        </IconButton>
        </Box>
      </Box>:
      <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: 2, // Spacing between Qty and Add to cart button
      }}
    >
      {/* Quantity Control */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <IconButton size="small" sx={{fontSize:14}}>
          <ArrowBackIosIcon style={{fontSize:14,color:'#475569'}}/>
        </IconButton>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "22px",
          }}
        >
          1 Qty
        </Typography>
        <IconButton size="small">
          <ArrowForwardIosIcon   style={{fontSize:14,color:'#475569'}}/>
        </IconButton>
      </Box>

      {/* Add to Cart Button */}
      <Button
        sx={{
          width: "105px",
          height: "32px",
          borderRadius: "8px",
          backgroundColor: "rgba(0, 122, 47, 0.2)", 
          color: "#007A2F", 
          textTransform: "none", 
          fontFamily: "Inter",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "22px",
          "&:hover": {
            backgroundColor: "rgba(0, 122, 47, 0.3)",
          },
        }}
      >
        Add to cart
      </Button>
    </Box>}
    </Paper>
    
    )
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      <SafeAreaView style={styles.container}>
        {/* Customizable Area Start */}
        {localStorage.getItem('user') && 
        <AppHeader navigtion={this.props.navigation} />}
        <ScrollView keyboardShouldPersistTaps="always">
          <TouchableWithoutFeedback
            onPress={() => {
              this.hideKeyboard();
            }}
            testID="hideKeyboard"
          >
            {/* Customizable Area Start */}
            {/* Merge Engine UI Engine Code */}
            <View>
             
              <View style={{marginHorizontal:'70px', marginTop:'10px'}}>
                <>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      padding: '8px 16px',
                      fontFamily: 'Inter, sans-serif',
                      marginBottom:'40px'
                      
                    }}
                  >
                    {/* Title */}
                    <div>
                      {this.state.isBuyer ? (
                        <>
                          <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#334155', fontSize: '16px' }}>
                            Products ({this.state.Products.length})
                          </Typography>
                          <span style={{ fontFamily: 'Inter', fontWeight: 400, fontSize: '14px', color: '#334155' }}>Based on filter or search result</span>
                        </>
                      ) : (
                        <Typography variant="h6" sx={{  fontFamily: 'Inter',fontSize: '20px',fontWeight: 'bold', color: '#0F172A',}}>
                          Your listings
                        </Typography>
                      )}
                    </div>

                    {/* Conditional Elements for Seller */}
                   

                    {/* Action Buttons */}
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    {!this.state.isBuyer && <>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          backgroundColor: '#F1F5F9',
                          borderRadius: '25px',
                          padding: '4px 8px',
                          marginLeft: '16px',
                          width:'360px',
                          height:'44px'
                        }}
                      >
                        <SearchIcon sx={{ color: '#94A3B8' }} />
                        <InputBase
                          placeholder="Search"
                          sx={{
                            marginLeft: 1,
                            fontSize: 14,
                            color: '#94A3B8',
                            '& input': {
                              padding: 0,
                            },
                          }}
                        />
                      </Box>

                        <Button
                          variant="contained"
                          sx={{
                            backgroundColor: '#067B3E',
                            color: '#FFFFFF',
                            height:'44px',
                            textTransform: 'none',
                            borderRadius: '20px',
                            padding: '6px 16px',
                            width:'197px',
                            fontSize: 14,
                            fontWeight:700,
                            '&:hover': {
                              backgroundColor: '#067B3E',
                            },
                          }}
                        >
                          Add Product
                        </Button>
                        </>
                      }

                      {/* Sort and View Options */}
                      <IconButton sx={{ color: '#334155' ,borderBottom: '1px solid #94A3B8 ',borderRadius:'0px' , width:'158px' , alignItems:'left',textAlign:'left', paddingLeft:'0'}}>
                        <SortIcon sx={{paddingLeft:'-10px',fontSize: 14,color:'#475569', }} />
                        <Typography sx={{ fontSize: 14,color:'#475569', marginLeft: '4px' , borderRadius:'0px', fontFamily:'inter' }}>Sort by &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
                      </IconButton>

                      {/* Sorting Menu */}
                      <Menu open={false}>
                        <MenuItem>Price</MenuItem>
                        <MenuItem>Name</MenuItem>
                        <MenuItem>Stock</MenuItem>
                      </Menu>

                      <IconButton sx={{
                        color: '#334155', cursor: 'pointer', opacity: 1, '&:disabled': {
                          opacity: 0.5, 
                          cursor: 'default',
                        },
                      }} onClick={this.hadelOpenList} disabled={this.state.openDrw}>
                        <FormatListBulletedRoundedIcon sx={{fontSize:'18px',color: '#334155',cursor:'pointer' }} />
                      </IconButton>
                      <IconButton sx={{ color: '#334155', cursor: 'pointer', opacity: 1, '&:disabled': {
                          opacity: 0.5,
                          cursor: 'default',
                        }, }} onClick={this.hadelOpenList} disabled={!this.state.openDrw}>
                        <WindowOutlinedIcon  sx={{fontSize:'18px',color: '#334155',cursor:'pointer' }}/>
                        
                      </IconButton>
                    </Box>
                  </Box>
                  
                {this.state.Products?<Grid 
                    container 
                    spacing={1}
                    columns={{ xs: 12, sm: 12, md: 12 , lg :12 }}
                    sx={{ alignItems: 'center' }}>
                    {this.state.Products.map((item, index) => (
                      !this.state.openDrw ? <Grid  item 
                      key={index} 
                      sx={{ 
                        margin: '0 ', 
                        display: 'block', 
                        justifySelf: 'center', 
                        alignSelf: 'center', 
                        marginRight: '30px', 
                        marginBottom: '30px', 
                        maxWidth: '300px',
                      }}
                      >
                        <ProductCard handelDelete={()=>this.DeleteProductDetails(+item.id)}  attributes={item.attributes} id={item.id}type={item.type} user={JSON.parse(localStorage.getItem('user')??"")} navigtion={()=>this.handleNavigation(`ProductDiscription`,`${item.id}`)} data-test-id='card'/>
                      </Grid>:
                        <Grid 
                        item 
                        xs={12} 
                        key={index} 
                        sx={{
                          textAlign: 'center',
                          display: 'flex',
                          marginBottom: '20px',
                          maxHeight:'125px'
                        }}
                      >
                        {this.LinearProductCard({attributes:item.attributes,id:item.id ,isBuyer:this.state.isBuyer ,handelDelete: () => this.DeleteProductDetails(+item.id) ,openDiscription:()=>this.handleNavigation(`ProductDiscription`,`${item.id}`)})}
                      </Grid>
                    ))}
                  
                </Grid>:  <img src={Bag.default} style={{width:'100%', height:'100%'}} data-test-id={"imageLoad"}/>}
                </>
              </View>
            
            </View>
            {/* Merge Engine UI Engine Code */}
            {/* Customizable Area End */}
          </TouchableWithoutFeedback>
        </ScrollView>
        {/* Customizable Area End */}
      </SafeAreaView>
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    width: "100vw",
    backgroundColor: "#ffffffff"
  },
  topContainer: {
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
    shadowOffset: { width: 1, height: 1 },
    shadowColor: "black",
    shadowOpacity: 1.0,
    backgroundColor: "#FFF"
  },
  topBox: {
    width: "100%",
    paddingVertical: 5,
    marginVertical: 12,
    justifyContent: "center",
    alignItems: "center"
  },
  topText: {
    textAlign: "center",
    fontSize: 16,
    color: "#000"
  },
  row: {
    flex: 1,
    justifyContent: "space-between"
  },
  sortIcon: {
    marginRight: 12
  },
  flexBox: {
    display: "flex",
    flexDirection: "row"
  },
  productContainer: {
    width: "100%",
    flex: 1,
    justifyContent: "center",
    backgroundColor: "white"
  },
  productBox: {
    height: 250,
    borderBottomWidth: 1,
    flexDirection: "column",
    flex: 0.5,
    borderRightWidth: 1,
    borderColor: "#ccc"
  },
  ImgContainer: {
    height: 150
  },
  productName: {
    paddingVertical: 5,
    fontSize: 16
  },
  price: {
    color: "#444",
    fontSize: 16,
    marginBottom: 10
  },
  productImg: {
    width: "100%",
    height: "100%"
  },
  detailContent: {
    padding: 10
  },
  rating: {
    color: "#000",
    paddingRight: 6
  },
  starBox: {
    alignItems: "center"
  }
});
// Customizable Area End
